@import "src/globalStyles/mainStyles.scss";
@import "src/globalStyles/colors.scss";
@import "src/globalStyles/fonts.scss";

// Colors
$background: $gray-800;
$text: $white;
$text-hover: $primary-accent-500;
$active-text-hover: $primary-accent-800;

// Sizes
$height: 60px;
$horizontal-padding: 35px;
$vertical-padding: 5px;
$hamburger-menu-min-width: 125px;

// Transitions
$header-link-color-transition: color 0.25s;
$hamburger-menu-transition: right 0.3s ease-in-out;

.nav {
  display: flex;
  position: sticky;
  height: 60px;
  top: 0;
  width: calc(100% - ($horizontal-padding * 2));
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $vertical-padding $horizontal-padding;
  background-color: $background;
  color: $text;
  z-index: 2;

  @include small-device {
    padding: calc($vertical-padding / 2) $horizontal-padding calc($vertical-padding / 2) calc($horizontal-padding / 2);
    width: calc(100% - ($horizontal-padding * 1.5));
  }
}

.logo_link {
  display: flex;
  flex-shrink: 1;
  align-items: center;

  @include small-device {
    width: 65vw;
  }
}

.logo {
  @include small-device {
    width: 100%;
    max-height: calc(50px - ($vertical-padding * 2));
    display: flex;
    float: left;
  }

  @include large-device {
    height: calc(50px - ($vertical-padding * 2));
  }
}

.menu_wrapper {
  @include small-device {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 15px 0;
    background-color: $background;
  }
}

.menu {
  flex-direction: row;
  gap: 40px;
  list-style: none;
  font-family: $secondary-font;
  font-weight: 100;
  letter-spacing: 0.025em;
  font-size: 21px;

  @include small-device {
    position: absolute;
    transition: $hamburger-menu-transition;
    background-color: $background;
    right: -200px;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
    padding: calc($height + $vertical-padding) $horizontal-padding $vertical-padding $horizontal-padding;
    height: calc(100vh - ($height + ($vertical-padding * 2)));
    z-index: 1;
    min-width: 125px;
    margin: 0;
  }

  @include large-device {
    position: static;
    display: flex;
  }
}

.expanded_menu {
  @include small-device {
    right: 0;
    transition: $hamburger-menu-transition;
  }
}

.hamburger_icon {
  z-index: 2;
  
  @include small-device {
    height: 1.5em;
    cursor: pointer;
  }

  @include large-device {
    display: none;
  }
}

.active_item {
  font-weight: 900;
  color: $text-hover;
  transition: $header-link-color-transition;
}

.active_item:hover {
  color: $active-text-hover;
}

.menu_link {
  text-decoration: none;
  color: inherit;
  transition: $header-link-color-transition;
}

.menu_link:hover {
  color: $text-hover;
}

// TODO: change useless class name
.menu_item {
  list-style: none;
}