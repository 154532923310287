$gray-100: #a0a5ab;
$gray-200: #90959d;
$gray-300: #7d838c;
$gray-400: #6e747c;
$gray-500: #5d6269;
$gray-600: #4f5359;
$gray-700: #3e4246;
$gray-800: #292b2e;
$gray-900: #1f2123;
$gray-1000: #111213;

$white: #ffffff;
$white-transparent: rgba(255, 255, 255, 0);

$primary-accent-100: #e4d6fa;
$primary-accent-200: #c0a5eb;
$primary-accent-500: #9165d8;
$primary-accent-800: #6116DC;