@import "src/globalStyles/mainStyles.scss";
@import "src/globalStyles/colors.scss";
@import "src/globalStyles/fonts.scss";

.card_list {
  display: flex;
  flex-shrink: 1;
  flex-direction: row;
  padding: 5vh 5vw;
  justify-content: center;
  gap: 5vw;
  flex-wrap: wrap;
  margin: 0 auto;
}